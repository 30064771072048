<template>
  <ZMainPage type="card">
    <template #footer>
      <el-button type="primary" @click="$router.back()">返回</el-button>
    </template>
    <div class="info-box mb-2 p-2">
      <div class="info-item">
        <div class="label">学生姓名：</div>
        <div class="value">{{ info.student_name }}</div>
      </div>
      <div class="info-item">
        <div class="label">学号：</div>
        <div class="value">{{ info.student_no }}</div>
      </div>
      <div class="info-item">
        <div class="label">辅导完成时间：</div>
        <div class="value">{{ info.coach_time }}</div>
      </div>
    </div>
    <div class="info-wrapper">
      <div class="info-content flex-1 mr-2">
        <div class="info-title pl-1">六大习惯监督</div>
        <div class="info-box p-2">
          <div class="flex justify-between align-center mb-1">
            <div class="info-inner-title mb">完成情况评分</div>
            <div>
              <el-rate :value="info.score" size="30" disabled active-color="#FFC75A" show-score :score-template="`${info.score || 0}分`"></el-rate>
            </div>
          </div>
          <div class="info-inner-title">监督工作记录</div>
          <div class="info-inner-content mb-2">{{ info.supervise }}</div>
          <div class="info-inner-title">完成情况图片</div>
          <div>
            <image-list :list="imgList"></image-list>
          </div>
        </div>
      </div>
      <div class="info-content flex-1">
        <div class="info-title pl-1">德育辅导</div>
        <div class="info-box p-2">
          <div class="info-inner-title">辅导类型</div>
          <div class="mb-2">
            <div class="info-tips-tag" v-for="item in info.coach_type" :key="item">{{ item }}</div>
          </div>
          <div class="info-inner-title">辅导内容</div>
          <div class="info-inner-content">{{ info.coach_content }}</div>
        </div>
      </div>
    </div>
  </ZMainPage>
</template>
<script>
import imageList from '@/components/ImageList'
export default {
	_config:{"route":{"path":"d-details","meta":{"title":"详情"}}},
  name: 'DDetails',
  components: { imageList },
  data() {
    return {
      info: {},
      imgList: []
    }
  },
  created() {
    this.getDetail()
  },
  methods: {
    getDetail() {
      let id = this.$route.query.id
      this.$_axios2.get('/api/teacher/moral-educationController/details?id=' + id).then((res) => {
        const {
          data: { data }
        } = res
        this.info = data
        this.imgList = data.img
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.info {
  &-box {
    background-color: #fff;
    border-radius: 4rem;
  }
  &-item {
    margin-right: 80rem;
    line-height: 24rem;
    font-size: 16rem;
    display: inline-flex;
    .label {
      margin-right: 40rem;
      color: #3C3C3C;
    }
    .value {
      color: #1E3AA2;
    }
  }
  &-wrapper {
    display: flex;
    justify-content: space-between;
  }
  &-title {
    margin-bottom: 16rem;
    font-size: 18rem;
    color: #1E3AA2;
    font-weight: 600;
  }
  &-content {
    font-size: 16rem;
    display: flex;
    flex-direction: column;
    .info-box {
      flex: 1;
    }
  }
  &-inner-title {
    margin-bottom: 16rem;
    color: #1E3AA2;
    font-weight: 600;
    line-height: 24rem;
    &.mb {
      margin-bottom: 0;
    }
  }
  &-inner-content {
    min-height: 160rem;
    padding: 15rem;
    font-size: 14rem;
    background-color: #F9FAFD;
  }
  &-tips-tag {
    padding: 13rem 16rem;
    margin-right: 40rem;
    font-size: 16rem;
    color: #598BFF;
    background-color: #F6F9FF;
    display: inline-block;
  }
}
::v-deep {
  .el-rate {
    height: 36rem;
  }
  .el-rate__icon {
    font-size: 36rem;
  }
  .el-rate__text {
    margin-left: 40rem;
    font-size: 24rem;
    color: #3C3C3C;
  }
}

</style>
